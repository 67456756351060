export default Object.freeze({
  // how we can optimize announcements endpoint
  // announcementEndpoint: {
  //   GET(id) {
  //     return id ? `announcements/${id}` : 'announcements'
  //   }
  // },
  addAnnouncement: {
    GET: "announcements",
  },
  editAnnouncement: {
    GET: (id) => {
      return `announcements/${id}`;
    },
  },
  getAnnouncement: {
    GET: (id) => {
      return `announcements/${id}`;
    },
  },
  getAnnouncementTypes: {
    GET: () => {
      return `announcements/types`;
    },
  },
  getAnnouncements: {
    GET: () => {
      return `announcements`;
    },
  },
  getAllAnnouncements: {
    GET: () => {
      return `announcements/allAnnouncements`;
    },
  },
  getSaleOffersAnnouncements: {
    GET: () => {
      return `announcements/saleOffer`;
    },
  },
  getMaritalStatusAnnouncements: {
    GET: () => {
      return `announcements/maritalStatus`;
    },
  },
  getGeneralAnnouncements: {
    GET: () => {
      return `announcements/generalAnnouncements`;
    },
  },
  deleteAnnouncement: {
    GET: (id) => {
      return `announcements/${id}`;
    },
  },
});
