import announcementEndpoints from "./endpoints/announcements-endpoints";
import apiConfig from "../config/api";
import http from "./http";
import store from "../store/index";

const {
  getAnnouncements,
  getAllAnnouncements,
  addAnnouncement,
  getAnnouncement,
  getAnnouncementTypes,
  deleteAnnouncement,
  editAnnouncement,
  getSaleOffersAnnouncements,
  getMaritalStatusAnnouncements,
  getGeneralAnnouncements,
} = announcementEndpoints;
const { baseURL } = apiConfig;

const Announcements = {
  async getAllAnnouncements() {
    const getAllAnnouncementsUrl = `${baseURL}${getAllAnnouncements.GET()}`;
    http.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${store.state.token}`;
    return await http
      .get(
        getAllAnnouncementsUrl,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${store.state.token}`,
          },
        }
      )
      .catch((error) => {
        return error;
      });
  },
  async getGeneralAnnouncements() {
    const getGeneralAnnouncementsUrl = `${baseURL}${getGeneralAnnouncements.GET()}`;
    http.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${store.state.token}`;
    return await http
      .get(
        getGeneralAnnouncementsUrl,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${store.state.token}`,
          },
        }
      )
      .catch((error) => {
        return error;
      });
  },
  async handleGetSaleOffersAnnouncements(token) {
    const getSaleOffersAnnouncementsUrl = `${baseURL}${getSaleOffersAnnouncements.GET()}`;
    http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return await http
      .get(
        getSaleOffersAnnouncementsUrl,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        }
      )
      .catch((error) => {
        return error;
      });
  },
  async handleGetMaritalStatusAnnuncements(token) {
    const getAllAnnouncementsUrl = `${baseURL}${getMaritalStatusAnnouncements.GET()}`;
    http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return await http
      .get(
        getAllAnnouncementsUrl,
        {},
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `token ${token}`,
          },
        }
      )
      .catch((error) => {
        return error;
      });
  },
  async handleAddAnnouncement(announcement) {
    const getAddAnnouncementUrl = `${baseURL}${addAnnouncement.GET}`;
    return await http
      .post(
        getAddAnnouncementUrl,
        {
          title: announcement.title,
          date: announcement.date,
          announcementTypeId: announcement.announcementTypeId,
          uri: announcement.uri,
          description: announcement.description,
          visible: announcement.visible,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .catch((error) => {
        return error;
      });
  },
  async handleEditAnnouncement(announcement) {
    const getEditAnnouncementUrl = `${baseURL}${editAnnouncement.GET(
      announcement.id
    )}`;
    return await http
      .put(
        getEditAnnouncementUrl,
        {
          id: announcement.id,
          title: announcement.title,
          date: announcement.date,
          announcementTypeId: announcement.announcementTypeId,
          uri: announcement.uri,
          description: announcement.description,
          visible: announcement.visible,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .catch((error) => {
        return error;
      });
  },

  async getAllAnnouncementTypes() {
    const getAnnouncementTypesUrl = `${baseURL}${getAnnouncementTypes.GET()}`;
    return await http
      .get(
        getAnnouncementTypesUrl,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .catch((error) => {
        return error;
      });
  },

  async handleGetAnnouncement(id) {
    const getAnnouncementUrl = `${baseURL}${getAnnouncement.GET(id)}`;
    return await http
      .get(
        getAnnouncementUrl,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .catch((error) => {
        return error;
      });
  },
  async handleDeleteAnnouncement(id) {
    const getDeleteAnnouncementUrl = `${baseURL}${deleteAnnouncement.GET(id)}`;
    return await http
      .delete(
        getDeleteAnnouncementUrl,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .catch((error) => {
        return error;
      });
  },
};

export default Announcements;
